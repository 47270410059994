import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import SideNav from "./components/sideNav";
import {h2Unlinked, castCard, calender, makeCalenderLink} from "./core/core";

import ScheduleDataProvider, {useScheduleData} from "./service/scheduleData"


function getSeletcedW(param){

    let selectedW = new Date().getDay()
    if(param !== undefined){ selectedW = param.split("_")[1] }
    return selectedW
}

function Body(){

    const {dateParam} = useParams();
    console.log(dateParam)

    const {scheduleData, getScheduleData} = useScheduleData()

    useEffect(()=>{
        getScheduleData(dateParam);
    },[dateParam]);


    return(
        <div className="container bg-w-alpha">
        <article className="main-colmn">
            {h2Unlinked("出勤スケジュール")}
            <nav className="block-calender" >{ makeCalenderLink(calender(), getSeletcedW(dateParam)) }</nav>
                    {scheduleData.length

                        ? <ul className="wrapper-c-lists">
                            {scheduleData.map((c)=>
                                <li key={c.c_id} className="card-cast">
                                    {castCard(c)}
                                </li>
                            )}
                         </ul>
                        : <p>登録されていません</p>                       
                    }
        </article>
        <SideNav />
    </div>
    )
}

function Schedule(){

    return(
        <ScheduleDataProvider>
            <Body />
        </ScheduleDataProvider>
    );
}

export default Schedule;