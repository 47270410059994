import { useEffect, useState } from "react";
import axios from "axios";
import {Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from "swiper";
import "swiper/css/bundle";

import {h2Unlinked, h2Linked, castCard, diaryArticle, nl2br} from"./core/core";
import { apiRoot, hvnApi, shopInfo, areaName, diaryApiRoot, hvnUriRoot, slideArr} from "./core/defines";

import PcNav from "./components/pcNav";
import SideNav from "./components/sideNav";
import Schedule from "./Schedule";
import CastDataProvider , {useCastData} from "./service/castData"
import ScheduleDataProvider, {useScheduleData} from "./service/scheduleData"


// SPスライドのみ 出口3つあってキモい
function slideMaker(obj){
    if(obj.link.uri !== null){
        if(obj.link.blank !== false ){
            return <SwiperSlide><a href={obj.link.uri} target="_blank" rel="noreferrer noopner"><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
            
        else{
            return <SwiperSlide><a href={obj.link.uri}><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></a></SwiperSlide> }
    }

    else{
        return <SwiperSlide><img src={ obj.img.imgPathSp } alt={ obj.img.alt } /></SwiperSlide> }
}

function limitedSlide(slideData, 
                      endDateFromat, 
                      startDateFromat=null){

    // console.log(slideData)
    
    const now = new Date()
    const end = new Date(endDateFromat)

    // 表示開始日時の指定あり
    if(startDateFromat !== null) {
        const start = new Date(startDateFromat)

        if(start.getTime() < now.getTime() &&
           now.getTime() < end.getTime()){
            
            return slideMaker(slideData)
        }
    }

    // 開始の指定なし (期日まで常設)
    else if(now.getTime() < end.getTime()){

        return slideMaker(slideData)
    }
}


function ScheduleBlock(){
    const {scheduleData, getScheduleData} = useScheduleData()
    useEffect(()=>{
        getScheduleData()
    }
    ,[])
    const ScheduleBody = (props)=>{

        return(
            <ul class="wrapper-sc">
                {props.casts.map((c) => 
                    <li className="card-cast" key={c.c_id}>
                        {castCard(c)}
                    </li>
                )}
            </ul>
        )
    }
    return(
        <article class="schedule block">
            {h2Linked("本日の出勤", "出勤一覧", "/schedule")}
            {scheduleData.length
                ? <ScheduleBody casts={scheduleData} />
                : <p>登録されていません</p>
            }
        </article>        
    )
}


function Body(){

    const {newfaceList, getCastList} = useCastData()
    useEffect(()=>{

        getCastList("newface", 10);
},[]);

const topBanner = slideArr[0].img.imgPathSp

    return(
        <>
            <header>
                <div id="sp-header" className="sp txt-center">
                    <p className="title">{areaName}のソープランド【{ shopInfo.name }】</p>
                    <p className="title">営業時間 { nl2br(shopInfo.openTime) }</p>
                    <div className="info">
                        <address>TEL { shopInfo.tel }</address>
                        <div>
                            <p><span>電話受付時間</span><br /> 平日:8:00~<br />土日祝:6:00~</p>
                        </div>
                    </div>
                </div>
                <p className="pc"><img src={topBanner} /></p>
                <PcNav />
            </header>
            <div className="container bg-w-alpha">
                <section id="home" className="main-colmn">
                    <section>
                        <Swiper
                            modules={[Autoplay]}
                            loop={true}
                            autoplay={true}
                            >
                                { /* limitedSlide({...syogatsuSlide}, "2023/1/4") */}
                                {slideArr.map((s)=>slideMaker(s))}
                        </Swiper>
                    </section>
                    <ScheduleDataProvider>
                        <ScheduleBlock />
                    </ScheduleDataProvider>
                    <div className="pc">
                        <article id="home-hvn-diary" className="block">
                            {h2Unlinked("写メ日記")}
                            <iframe className="wrappar-hvn-video block" src={hvnApi.Diary}></iframe>
                            <a className="btn" href={ hvnUriRoot+ "/diarylist"}
                                target="_blank" rel="noreferrer noopner">一覧はこちら!</a>

                        </article>
                        <article id="hvn-videos" className="wrappar-hvn-video block">
                            {h2Unlinked("MOVIE")}
                            <iframe src={hvnApi.Video} />
                        </article>
                    </div>
                    <article className="newface block">
                        {h2Linked("新人", "新人一覧", "/newface")}
                        {newfaceList.length >0
                        ? <Swiper
                            modules={[Autoplay]}
                            autoplay={true}
                            loop={true}

                            slidesPerView={1.2}
                            centeredSlides={true}
                            spaceBetween={5}

                            breakpoints={{ 768:{slidesPerView:3},}}
                            >
                                {newfaceList.map((el)=>
                                    <SwiperSlide
                                        className="card-cast txt-center"
                                        key={el.C_id}>
                                            {castCard(el, false)}
                                    </SwiperSlide>
                                )}
                            </Swiper>

                        : <p>登録されていません</p>
                        }
                    </article>
                    <article className="parent-access">
                        {h2Unlinked("ACCESS")}
                        <div className="box">
                            <address>〒{shopInfo.zipCode}<br />{shopInfo.zipAddress}</address>
                            <img  src={shopInfo.alterGmapImg} alt="店舗の地図" />
                        </div>
                        {
                            /*
                            <iframe className="iframe-map" src={shopInfo.gmapUri}></iframe>
                            */
                        }
                    </article>
                </section>
                <SideNav />
            </div>
        </>
    );
}


function Home(){
    return(
            <CastDataProvider>
                <Body />
            </CastDataProvider>
    )
}

export default Home;