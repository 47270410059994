import { shopInfo } from "./core/defines";
import { h2Unlinked } from "./core/core";
import SideNav from "./components/sideNav";

function Access(){

    return(
        <div className="container bg-w-alpha">
            <main id="access" className="main-colmn">
                { h2Unlinked("ACCESS") }
                <div className="layout-box">
                    <h3>{shopInfo.name}</h3>
                    <table className="info txt-left">
                        <tbody>
                            <tr><th>営業時間</th><td>{ shopInfo.openTime }</td></tr>
                            <tr><th>所在地</th><td>〒{ shopInfo.zipCode } { shopInfo.zipAddress }</td></tr>
                            <tr><th>TEL</th><td>{ shopInfo.tel }</td></tr>
                        </tbody>
                    </table>
                    <img src={shopInfo.alterGmapImg} alt="店舗所在地の画像" />
                    {
                        /*
                            <iframe className="gmap" src={ shopInfo.gmapUri }></iframe>
                        */
                    }
                </div>
            </main>
            <SideNav />
        </div>
    )
}
export default Access