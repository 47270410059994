import { hvnApi } from "../core/defines";

function sideNav(){

    return(
        <section id="side-nav" className="layout-box">
            <div className="sp">
                <article className="wrappar-hvn-diary"><iframe src={hvnApi.Diary} /></article>
                <article className="wrappar-hvn-video"><iframe src={hvnApi.Video} /></article>
            </div>
            <article className="wrappar-hvn-comments"><iframe src={hvnApi.Comment} /></article>
        </section>
    );
}
export default sideNav;
