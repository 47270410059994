import React, {createContext, useState, useContext} from "react"
import axios from "axios"

import { apiRoot, apiShopId } from "../core/defines"


const ScheduleDataContext = createContext()
export const useScheduleData =()=>useContext(ScheduleDataContext)

export default function CastDiaryDataProvider({children}){
    const [scheduleData, setScheduleData] = useState([])


    const getScheduleData = (param) =>{
        // 未入力なら当日値取得(デフォ値)
        let target = `${apiRoot}/schedule?s=${apiShopId}`
        if(param !== undefined){
            target += `&d=${param}`}
    
        axios({
        method: "get",
        url: target,
        })
        .then((res) => {
            console.log(res)
            setScheduleData(res.data);
        })
        .catch(err =>{
            console.log("err", err);
        });
    }
    



    return(
        <ScheduleDataContext.Provider value={{
            scheduleData, getScheduleData
        }}>
            {children}
        </ScheduleDataContext.Provider>
    )
}