import { useEffect, useState } from "react";
import axios from "axios";
import {h2Unlinked, castCard} from"./core/core";
import { apiRoot } from "./core/defines";
import SideNav from "./components/sideNav";

import CastDataProvider, {useCastData} from "./service/castData";

function Body(){
    const {newfaceList, getCastList} = useCastData()
    
    useEffect(()=>{
        
            getCastList("newface");
    },[]);

    return(
        <div className="container bg-w-alpha">
            <section className="main-colmn">
                {h2Unlinked("NEW FACE")}
                {newfaceList.length > 0 
                    ? <ul class="wrapper-sc">
                        {newfaceList.map((cast) => 
                            <li className="card-cast" key={cast.c_id}>
                                {castCard(cast, false)}
                            </li>
                        )}
                     </ul>

                    : <p>登録されていません</p>
                }
            </section>
            <SideNav />
        </div>
    )
}

function NewFace(){

    return (
        <CastDataProvider>
            <Body />
        </CastDataProvider>
    )
}

export default NewFace