import { useEffect, useState } from "react";
import axios from "axios";
import {h2Unlinked, castCard} from"./core/core";
import { apiRoot } from "./core/defines";
import SideNav from "./components/sideNav";

import CastDataProvider, {useCastData} from "./service/castData";


function Body(){
    const {castList, getCastList} = useCastData()
    
    useEffect(()=>{
        getCastList()
    },[]);

    return(
        <div className="container bg-w-alpha">
            <section className="main-colmn">
                {h2Unlinked("ALL CASTS")}
                {castList.length > 0

                ? <ul class="wrapper-sc">
                    {castList.map((cast) => 
                        <li className="card-cast" key={cast.c_id}>
                            {castCard(cast)}
                        </li>
                    )}
                 </ul>

                : <p>登録されていません</p>
                }
            </section>
            <SideNav />
        </div>
    )
}


function Allcasts(){
    return(
        <CastDataProvider>
            <Body />
        </CastDataProvider>
    )
}

export default Allcasts